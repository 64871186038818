<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Nuevo"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              /> 
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Clientes"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Clientes</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column
            field="code"
            header="Código"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              {{ slotProps.data.code }}
            </template>
          </Column>
          <Column
            field="name"
            header="Nombre"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column
            field="code"
            header="CUIT"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">CUIT</span>
              {{ slotProps.data.cuit }}
            </template>
          </Column>
          <Column
            field="type_client"
            header="Tipo"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Tipo</span>
              {{ afipType(slotProps.data.type_client) }}
            </template>
          </Column>
          <Column
            field="email"
            header="E-mail"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">E-mail</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column
            field="location"
            header="Ciudad"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Ciudad</span>
              {{ slotProps.data.location }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px' }"
          header="Cliente"
          :modal="true"
          class="p-fluid"
        >
          <Button label="Datos aleatorios" icon="pi pi-refresh" class="p-button-success" @click="fakerGenerate()" />
          <div class="field">
            <label for="name">Código</label>
            <InputText
              id="name"
              v-model.trim="product.code"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.code }"
            />
            <small class="p-invalid" v-if="submitted && !product.code"
              >Código es requerido.</small
            >
          </div>
          <div class="field">
            <label for="name">Nombre / Razon Social</label>
            <InputText
              id="name"
              v-model.trim="product.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.name }"
            />
            <small class="p-invalid" v-if="submitted && !product.name"
              >Nombre es requerido.</small
            >
          </div>
          <div class="field">
            <label for="name">E-mail</label>
            <InputText
              id="name"
              v-model.trim="product.email"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.email }"
            />
            <small class="p-invalid" v-if="submitted && !product.email"
              >E-mail es requerido.</small
            >
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <label for="name">CUIT</label>
              <InputText
                id="cuit"
                v-model.trim="product.cuit"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.cuit }"
              />
              <small class="p-invalid" v-if="submitted && !product.cuit"
                >CUIT es requerido.</small
              >
            </div>
            <div class="field col">
              <label for="name">IIBB</label>
              <InputText
                id="iibb"
                v-model.trim="product.iibb"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.iibb }"
              />
              <small class="p-invalid" v-if="submitted && !product.iibb"
                >IIBB es requerido.</small
              >
            </div>
          </div>
          <div class="field">
            <label for="name">Dirección</label>
            <InputText
              id="iibb"
              v-model.trim="product.address"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.address }"
            />
            <small class="p-invalid" v-if="submitted && !product.address"
              >Dirección es requerido.</small
            >
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <label for="location">Ciudad</label>
              <InputText id="location" v-model="product.location" />
            </div>
            <div class="field col">
              <label for="cp">CP</label>
              <InputNumber id="cp" v-model="product.postalcode" integeronly />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <label for="province">Provincia</label>
              <InputText id="province" v-model="product.province" />
            </div>
            <div class="field col">
              <label for="phone">Telefono</label>
              <InputText id="phone" v-model="product.phone" />
            </div>
          </div>
          <div class="field">
            <label class="mb-3">Tipo</label>
            <div class="formgrid grid">
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="category1"
                  name="type_client"
                  value="1"
                  v-model="product.type_client"
                />
                <label for="type_client1">Responsable Inscripto</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client2"
                  name="type_client"
                  value="2"
                  v-model="product.type_client"
                />
                <label for="type_client2">Monotributista</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client3"
                  name="type_client"
                  value="3"
                  v-model="product.type_client"
                />
                <label for="type_client3">Exento</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client4"
                  name="type_client"
                  value="4"
                  v-model="product.type_client"
                />
                <label for="type_client4">Consumidor Final</label>
              </div>
            </div>
          </div>
          <div class="field">
            <label for="name">Web</label>
            <InputText
              id="web"
              v-model.trim="product.web"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.web }"
            />
            <small class="p-invalid" v-if="submitted && !product.web"
              >Web es requerido.</small
            >
          </div>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmación"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Eliminaras a <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product">Seguro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "./../services/user.service";
import { FilterMatchMode } from "primevue/api";
import CrudService from "../services/crud.service";

import faker from "../../public/extras/faker.min.js";

class Person {
	constructor() {
		this.name = faker.name.firstName();
    
		this.phone  = faker.phone.phoneNumber();
		this.email = faker.internet.email();
    this.address = faker.address.streetAddress();
    this.location = faker.address.city();
    this.postalcode = faker.random.number({min: 1, max: 4});
    this.province = faker.address.state();
    this.web = faker.internet.url();
    this.type_client = faker.random.number({min: 1, max: 4});

    this.cuit = faker.random.number({min: 1, max: 999999999});
    this.iibb = faker.random.number({min: 1, max: 999999999});

	}
}


export default {
  data() {
    return {
      routeName: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      validationErrors: [],
    };
  },
  
  created() {
    // get route name
    this.routeName = this.$route.name;
    
    this.initFilters();
  },
  mounted() {
    CrudService.getCRUD('api/clients').then(
      (data) => (this.products = data)
    );
  },
  methods: {
    fakerGenerate(){

			let person = new Person();
			console.log(person)
      this.product = person
		},
    afipType(value) {
      if (value == 1) {
        return "Responsable Inscripto";
      }
      if (value == 2) {
        return "Monotributista";
      }
      if (value == 3) {
        return "Exento";
      }
      if (value == 4) {
        return "Consumidor Final";
      }

      return " - ";
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          CrudService.updateCRUD('api', 'clients', this.product).then(
            (data) => {

            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Actualizado correctamente.",
                life: 3000,
              });
              this.products[this.findIndexById(this.product.id)] = this.product;
              this.productDialog = false;
              this.product = {};
            }
          });
        } else {
          CrudService.createCRUD('api', 'clients', this.product).then(
            (data) => {
              
            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);
              
              } else {
                this.products.push(this.product);
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                this.productDialog = false;
                this.product = {};
              }
            }
          );
        }
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      
      CrudService.deleteCRUD('api','clients', this.product.id).then(
        (data) => {

          this.products = this.products.filter((val) => val.id !== this.product.id);

          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Eliminado correctamente.",
            life: 3000,
          });
        }
      );

      this.deleteProductDialog = false;
      this.product = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Éxito!",
        detail: "Eliminado correctamente.",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
